import React from 'react';
import './App.css';

function App() {
	return (
		<div className='App Test'>
			<header className='App-header'>
				<h1>
					om<span className='u-color--warning'>t</span>anke.
				</h1>
				<a
					className='App-link'
					href='mailto:info@omtankestudio.com'
					rel='noopener noreferrer'>
					info
				</a>
			</header>
		</div>
	);
}

export default App;
